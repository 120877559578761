var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[(_vm.showGeneralResults)?_c('v-row',[_c('v-col',[_c('general-results',{attrs:{"chart-data":_vm.specificChartData,"general-chart-data":_vm.chartData,"multiplier":_vm.targetMultiplier,"display-chart":{
          scheduledInspections: true,
          purchases: true,
          approvedCredits: false,
          signedCredits: true,
          reserves: true,
          sales: true,
        },"id-employee":_vm.idEmployee,"title":"Mis resultados"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('financial-evaluation-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reports', queryMethod: _vm.getFinancialReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reports', queryMethod: getFinancialReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.reports,"id-executive":_vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('inspections-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspectionsReports', queryMethod: _vm.getInspectionReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspectionsReports', queryMethod: getInspectionReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.inspectionsReports,"id-executive":_vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('purchases-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchasesReports', queryMethod: _vm.getPurchasesReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchasesReports', queryMethod: getPurchasesReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.purchasesReports,"id-executive":_vm.idEmployee}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('credits-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'creditsReports', queryMethod: _vm.getCreditsReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'creditsReports', queryMethod: getCreditsReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.creditsReports,"id-executive":_vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('reserve-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesReports', queryMethod: _vm.getReserveReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesReports', queryMethod: getReserveReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.reservesReports,"id-executive":_vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('sales-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'salesReports', queryMethod: _vm.getSalesReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'salesReports', queryMethod: getSalesReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.salesReports,"id-executive":_vm.idEmployee}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Compras"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"filtering-criteria":record => record.executive.id === _vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Créditos"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedCredits,"records-settings":_vm.chartData.settings.loanEvaluations,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixCreditFields,"path-to-details":[],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate'],"filtering-criteria":record => record.executive.id === _vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Ventas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"records-settings":_vm.chartData.settings.sale,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixSaleFields,"path-to-details":[],"path-to-title":['carFullName'],"path-to-subtitle":['registrationPlate'],"filtering-criteria":record => record.executive.id === _vm.idEmployee}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Solicitudes de financiamiento actuales"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('loan-applications',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'evaluations', queryMethod: _vm.getFinancing, queryFilter: _vm.currentMonthFilters.loanApplications})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'evaluations', queryMethod: getFinancing, queryFilter: currentMonthFilters.loanApplications})\n            }"}],attrs:{"loan-evaluations":_vm.chartData.currentMonth.evaluations}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Acumulado de negocios"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.specificCurrentMonthFilters.stockCreatedDate},
                  {category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.specificCurrentMonthFilters.startEndDate},
                  {category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.specificCurrentMonthFilters.signedCredits},
                  {category: 'prevMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.specificPrevMonthFilters.stockCreatedDate},
                  {category: 'prevMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.specificPrevMonthFilters.startEndDate},
                  {category: 'prevMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.specificPrevMonthFilters.signedCredits},
                ], true)}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: specificCurrentMonthFilters.stockCreatedDate},\n                  {category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: specificCurrentMonthFilters.startEndDate},\n                  {category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: specificCurrentMonthFilters.signedCredits},\n                  {category: 'prevMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: specificPrevMonthFilters.stockCreatedDate},\n                  {category: 'prevMonth', field: 'sales', queryMethod: getSales, queryFilter: specificPrevMonthFilters.startEndDate},\n                  {category: 'prevMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: specificPrevMonthFilters.signedCredits},\n                ], true)}"}],attrs:{"data":[
              {
                records: _vm.specificChartData.currentMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'line',
              },
              {
                records: _vm.specificChartData.currentMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'line',
              },
              {
                records: _vm.specificChartData.currentMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'line',
                dataTransformer: (records) => {
                  return records.filter(record => record.executive === _vm.idEmployee)
                },
              },
            ],"prev-month-data":[
              {
                records: _vm.specificChartData.prevMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'line',
              },
              {
                records: _vm.specificChartData.prevMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'line',
              },
              {
                records: _vm.specificChartData.prevMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'line',
                dataTransformer: (records) => {
                  return records.filter(record => record.executive === _vm.idEmployee)
                },
              },
            ],"dates":_vm.dates,"show-total":false,"accumulated":true,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('dashboard-card',{attrs:{"title":"Negocios último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.specificLastYearFilters.stockCreatedDate},
                {category: 'lastYear', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.specificLastYearFilters.startEndDate},
                {category: 'lastYear', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.specificLastYearFilters.signedCredits},
              ], true)
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'purchases', queryMethod: getPurchases, queryFilter: specificLastYearFilters.stockCreatedDate},\n                {category: 'lastYear', field: 'sales', queryMethod: getSales, queryFilter: specificLastYearFilters.startEndDate},\n                {category: 'lastYear', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: specificLastYearFilters.signedCredits},\n              ], true)\n            }"}],attrs:{"data":[
              {
                records: _vm.specificChartData.lastYear.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'bar',
              },
              {
                records: _vm.specificChartData.lastYear.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'bar',
              },
              {
                records: _vm.specificChartData.lastYear.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'bar',
                dataTransformer: (records) => {
                  return records.filter(record => record.executive === _vm.idEmployee)
                },
              },
            ],"prev-month-data":[],"monthly":true,"dates":_vm.lastYearDates,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }